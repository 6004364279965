import "core-js/stable";
import "regenerator-runtime/runtime";
import '../scss/style.scss';
import barba from '@barba/core/dist/barba';
import barbaPrefetch from '@barba/prefetch/dist/barba-prefetch';
import select from 'dom-select';
import { classy as cl } from './helpers/classy';
import { scrollLock, scrollUnLock } from './helpers/scrollLock'
import SmoothScroll from 'smooth-scroll';
import throttle from 'lodash.throttle';
//import imagesLoaded from 'imagesloaded';

barba.use(barbaPrefetch);

function checkMessageContent(message, captcha){
  if ( message.value !== '' ){
    message.parentElement.classList.add('fullsize');
    captcha.classList.add('show-captcha');
  }else{
    message.parentElement.classList.remove('fullsize');
    captcha.classList.remove('show-captcha');
  }
}

function messageIncrease(){
  
  //Increase the size of the textarea form field on focus or if there is content
  var message = document.getElementById('message');
  var captcha = document.getElementById('g-recaptcha-block');

  if( message !== null  ){    
    message.addEventListener("focus", function(){
      message.parentElement.classList.add('fullsize');
      captcha.classList.add('show-captcha');
      console.log('show cap');
    });
    
    message.addEventListener("focusout", function(){
      checkMessageContent(message, captcha);
    })
    
    checkMessageContent(message, captcha);
  }
  
}

function onloadCallback() {
     
  var recaptchaSiteKey = document.querySelector('.g-recaptcha');
  var newSiteKey = document.getElementById('g-recaptcha-block');
  var key = '';
  if( newSiteKey ){
    
    if( recaptchaSiteKey !== 'undefined'){
      key = recaptchaSiteKey.dataset.sitekey;
      newSiteKey.innerHTML = "";
      newSiteKey.dataset.sitekey = key;
    }
    else if( newSiteKey.dataset.sitekey ){
        key = newSiteKey.dataset.sitekey;
    }
    
    if( key ){
      grecaptcha.render('g-recaptcha-block', {
        'sitekey' : key
      }); 
    }
    
  }
};

window.onloadCallback = onloadCallback;


function checkForm(){
  var formCaptcha = document.getElementById('g-recaptcha-block');
  
  if( formCaptcha && typeof(grecaptcha) === 'undefined' ){
    getScript('https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit', function() { });
  }else{
    onloadCallback();
  }
}


function getScript(source, callback) {
  var el = document.createElement('script');
  el.onload = callback;
  el.src = source;
  
  document.body.appendChild(el);
}




(function (doc, win) {
  require('viewport-units-buggyfill').init();
  window.openModal = false;
  window.dataLayer = window.dataLayer || [];
  let app = {};
  messageIncrease();
  checkForm();

  const nav = function () {
    const burger = select('.burger');
    const header = select('.header');
    const barbaCont = select('.barba-container');

    function updateHeader(namespace) {
      cl(header).rmCl('size1').rmCl('size2')

      switch(namespace) {
        case 'homepage':
          cl(header).addCl('size2');
          break;
        case 'recipe':
        case 'food': 
          cl(header).addCl('');
          break
        default :
          cl(header).addCl('size1');
          break;
      }
    }


    if (burger) burger.addEventListener('click', function () {
      if (burger.classList.contains('open')) {
        scrollUnLock()
      } else {
        scrollLock(header);
      }
      cl(header).toCl('open');
      cl(burger).toCl('open');
    });

    this.close = function () {
      cl(header).rmCl('open');
      cl(burger).rmCl('open');
      window.openModal = false;
      scrollUnLock()
      cl('.mobile-drop').rmCls('open')
    };

    this.scroll = function () {
      if (window.pageYOffset > 200) cl(header).addCl('fixed');
      else cl(header).rmCl('fixed');
      if (window.pageYOffset > 400) cl(header).addCl('show');
      else cl(header).rmCl('show');
    };

    this.updateHeader = updateHeader

    if (barbaCont) {
      updateHeader(barbaCont.id)
    }
  };

  function activeNav() {
    const firstSegment = location.pathname.split('/')[1];
    const lastSegment = location.pathname.split('/').pop();
    select.all('.header a, .nav a').forEach(function(nav) {
      const linkSegments = nav.pathname.split('/')
      const linkFirstSegment = linkSegments.length > 1 && linkSegments[1] !== '' ? linkSegments[1] : false
      const linkSecondSegment = linkSegments.length > 2 && linkSegments[2] !== '' ? linkSegments[2] : false

      if (linkSecondSegment && linkSecondSegment === lastSegment) cl(nav).addCl('active');
      else if (linkFirstSegment && !linkSecondSegment && linkFirstSegment === firstSegment) cl(nav).addCl('active');
      else cl(nav).rmCl('active');
    });
  }

  function smooth(el, offset, speed) {
    if (el) {
      const scroll = new SmoothScroll();
      scroll.animateScroll(el, false, {
        offset: offset || 200,
        updateURL: false,
        speed: speed || 600,
        durationMax: speed || 600
      });
    }
  }

  function setSmooth(parent) {

    select.all('.smooth-scroll', parent).forEach(anchor => {
      anchor.addEventListener('click', ev => {
        ev.preventDefault()
        ev.stopPropagation()
        const target = select(anchor.hash)
        if (target) smooth(target, 40, 1000)
      })
    })
  }
  
  

  function outBarba() {
    app.nav = new nav();
  }

  function onBarba(parent) {
    // remove on production
    if (win.location.hostname === 'localhost') {
      select.all('a').forEach(a => {
        a.href = a.href.replace('http://pitango.test','')
      })
    }
    activeNav();
    if (parent) {
      setSmooth(parent);
      select.all('video', parent).forEach(v => {
        v.play()
      })
    }
    
     

  }

  doc.addEventListener('DOMContentLoaded', function () {
    cl('body').rmCl('toload');
    cl('.barba-container').rmCl('toload');
    outBarba();

    win.addEventListener('scroll', throttle(function () {
      if (app.nav) app.nav.scroll();
    }, 50));

    win.addEventListener('resize', throttle(function () {
      if (app.nav) app.nav.close();
    }, 300));
    
   

    barba.init({
      timeout: 5000,
      prefetchIgnore: true,
      transitions: [
        {
          name: 'transition',
          from: {
            custom: () => {
              return window.openModal === false;
            }
          },
          leave() {
            const done = this.async();
            cl('body').addCl('toload');
            setTimeout(done, 300);
          }
        }
      ]
    });

    barba.hooks.enter(data => {
      if (app.nav) app.nav.close();
      window.scrollTo(0, 0);
      cl('body').rmCl('toload');
      if (app.nav) app.nav.updateHeader(data.next.namespace)
      setTimeout(() => {
        cl(data.next.container).rmCl('toload');
      }, 10)
    });

    barba.hooks.after(() => {
      if (typeof ga !== 'undefined') {
        ga('set', 'page', window.location.pathname);
        ga('send', 'pageview');
      }
      
       //If there is an HTML5 video start it
     var vid = document.getElementById("video");
     if( vid && vid.paused ){
       vid.play();
     }
     
     messageIncrease();
     checkForm();
      
    });
  });
})(document, window);
